@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Raleway:wght@600&display=swap');



/* Custom styles for better visuals */
body {
  font-family: 'Inter', sans-serif;
}

.grid-areas {
  display: grid;
  grid-template-areas:
    "terminal"
    "settings"
    "airdrop";
}

@media (min-width: 640px) {
  .grid-areas {
    grid-template-areas:
      "terminal airdrop"
      "settings airdrop";
  }
}

@media (min-width: 1024px) {
  .grid-areas {
    grid-template-areas: "terminal airdrop settings";
  }
}

.area-terminal {
  grid-area: terminal;
}

.area-settings {
  grid-area: settings;
}

.area-airdrop {
  grid-area: airdrop;
}

.embla {
  position: relative;
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 100%;
}

.embla__slide img {
  width: 100%;
  height: auto;
}

.embla__prev,
.embla__next {
  position: absolute;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.embla__prev {
  left: 10px;
}

.embla__next {
  right: 10px;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

h2, h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: semi-bold;
}